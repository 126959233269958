// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/map-bg1.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".echarts {\n  width: 100%;\n  height: 317px;\n  min-height: 317px;\n  margin: 0 auto;\n}\n.echarts .block {\n    min-width: 96px;\n    padding: 4px 0;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n    background-size: 100% 100%;\n}\n.echarts .province {\n    color: rgba(0, 0, 0, 0.85);\n    border-bottom: 1px solid #f5f5f5;\n    padding: 5px;\n    font-size: 12px;\n    line-height: 12px;\n    font-weight: 500;\n}\n.echarts .num {\n    font-size: 12px;\n    padding: 5px 5px 10px;\n    line-height: 12px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n", ""]);
// Exports
module.exports = exports;
